<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height class="auth-container-bg">
        <slot />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
.auth-container-bg {
  background-color: #1f1d1d;
}
</style>
